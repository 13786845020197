import cn from 'clsx'
import Badge from 'components/Luxkit/Badge'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import { setSvgIconSize } from 'components/Luxkit/SvgIcon'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { useAppSelector } from 'hooks/reduxHooks'
import { rem } from 'polished'
import React from 'react'
import { hasAccountNotification } from 'selectors/accountSelectors'
import styled from 'styled-components'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { getHasLuxLoyaltyAccount } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { LargeButtonTypography } from 'components/Luxkit/Typography/ButtonTypography'
import useCurrentLoyaltyTier from 'luxLoyalty/hooks/useCurrentLoyaltyTier'

const IconContainer = styled.div`
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
`

const LuxPlusRing = styled.div`
  grid-column: 1;
  grid-row: 1;
  height: ${rem(32)};
  width: ${rem(32)};
  border-radius: ${props => props.theme.borderRadius.round};
  border: 2px solid ${props => props.theme.palette.product.luxPlus.background};
  background-color: ${props => props.theme.palette.neutral.default.eight};

  ${mediaQueryUp.tablet} {
    height: ${rem(40)};
    width: ${rem(40)};
  }
`

const NotificationBadge = styled(Badge)`
  position: absolute;
  top: calc(30%);
  transform: translateY(-50%) translateX(100%);
  right: 0;
`

const Avatar = styled.div`
  position: relative;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(24)};
  width: ${rem(24)};
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.neutral.default.one};
  text-transform: uppercase;
  ${setSvgIconSize(16)}
  ${LargeButtonTypography}
  font-weight: ${props => props.theme.font.secondary.weight.bold};
  color: ${props => props.theme.palette.neutral.default.eight};
  border: 2px solid transparent;

  ${mediaQueryUp.tablet} {
    ${setSvgIconSize(16)}
    height: ${rem(32)};
    width: ${rem(32)};
  }

  &.lux-plus-member {
    background-color: ${props => props.theme.palette.product.luxPlus.background};
  }

  &.tier-bronze {
    background: linear-gradient(45deg, ${props => props.theme.palette.product.luxLoyalty.bronze.lightBackground} 30%, ${props => props.theme.palette.product.luxLoyalty.bronze.normalBackground} 70%);
    border-color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalForeground};
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast};
  }
  &.tier-silver {
    background: linear-gradient(45deg, ${props => props.theme.palette.product.luxLoyalty.silver.lightBackground} 30%, ${props => props.theme.palette.product.luxLoyalty.silver.normalBackground} 70%);
    border-color: ${props => props.theme.palette.product.luxLoyalty.silver.normalForeground};
    color: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast};
  }
  &.tier-gold {
    background: linear-gradient(45deg, ${props => props.theme.palette.product.luxLoyalty.gold.lightBackground} 30%, ${props => props.theme.palette.product.luxLoyalty.gold.normalBackground} 70%);
    border-color: ${props => props.theme.palette.product.luxLoyalty.gold.normalForeground};
    color: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast};
  }
  &.tier-platinum {
    background: linear-gradient(45deg, ${props => props.theme.palette.product.luxLoyalty.platinum.lightBackground} 30%, ${props => props.theme.palette.product.luxLoyalty.platinum.normalBackground} 70%);
    border-color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalForeground};
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalContrast};
  }
`

function NavBarUserAvatar() {
  const account = useAppSelector(state => state.auth.account)
  const isLuxPlusMember = useAppSelector(state => checkCanRedeemLuxPlusBenefits(state))
  const isLuxLoyaltyMember = useAppSelector(state => getHasLuxLoyaltyAccount(state))
  const tier = useCurrentLoyaltyTier()
  const isLuxPlusAndLuxLoyaltyMember = isLuxPlusMember && isLuxLoyaltyMember
  const showNotification = useAppSelector(hasAccountNotification)

  const oneInitial = account.givenName?.[0]

  return <IconContainer>
    {isLuxPlusAndLuxLoyaltyMember && <LuxPlusRing />}
    <Avatar className={cn(
      { 'lux-plus-member': isLuxPlusMember },
      { [`tier-${tier}`]: isLuxLoyaltyMember },
      { 'lux-plus-and-lux-loyalty-member': isLuxPlusAndLuxLoyaltyMember },
    )}
    >
      {oneInitial}
      {!oneInitial && <LineUserIcon />}
      {showNotification && <NotificationBadge
        mode="critical"
        kind="dot"
      />}
    </Avatar>
  </IconContainer>
}

export default React.memo(NavBarUserAvatar)
