import LineCommentsIcon from 'components/Luxkit/Icons/line/LineCommentsIcon'
import React from 'react'
import NavBarButton from '../NavBarButton/NavBarButton'
import { useAppSelector } from 'hooks/reduxHooks'
import { isSupportAssistantEnabled } from 'selectors/featuresSelectors'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import useOrders from 'hooks/Order/useOrders'
import Group from 'components/utils/Group'
import Caption from 'components/Luxkit/Typography/Caption'

interface Props {
  className?: string;
}

function NavBarChatButton(props: Props) {
  const chatEnabled = useAppSelector(isSupportAssistantEnabled)
  const [upcomingOrders] = useOrders('upcoming')
  const hasUpcomingOrder = !!upcomingOrders.length

  if (!chatEnabled) {
    return null
  }

  return <Group direction="horizontal" gap={4} verticalAlign="center" className={props.className}>
    {hasUpcomingOrder && <Caption variant="medium" wrap="no-wrap">
      Need help with your booking?
    </Caption>}
    <NavBarButton
      icon={<LineCommentsIcon />}
      to="/support"
      size="small"
    >
      Chat<CSSBreakpoint as="span" min="desktop"> Now</CSSBreakpoint>
    </NavBarButton>
  </Group>
}

export default React.memo(NavBarChatButton)
