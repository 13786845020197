import Clickable from 'components/Common/Clickable'
import { setSvgIconSize } from 'components/Luxkit/SvgIcon'
import { MediumButtonTypography } from 'components/Luxkit/Typography/ButtonTypography'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import styled from 'styled-components'

const NavBarButtonBase = styled(Clickable)`
  ${MediumButtonTypography}
  font-family: ${props => props.theme.font.secondary.family};
  font-weight: ${props => props.theme.font.secondary.weight.regular};
  position: relative;
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;
  flex-shrink: 0;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  display: inline-flex;
  align-items: center;
  text-align: center;
  transition: background-color 0.2s;
  color: ${props => props.theme.palette.neutral.default.one};
  ${setSvgIconSize(20)};

  &:not(:disabled) {
    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.neutral.default.seven};
      }
    }

    &:focus {
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px) solid ${props => props.theme.palette.neutral.default.five};
      outline-offset: 1px;
    }

    &:active {
      background-color: ${props => props.theme.palette.neutral.default.six};
    }
  }
`

export default NavBarButtonBase
