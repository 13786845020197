import FormatCurrency from 'components/Common/FormatCurrency'
import BodyText from 'components/Luxkit/Typography/BodyText'
import CurrencyContext from 'contexts/currencyContext'
import { useAppSelector } from 'hooks/reduxHooks'
import { rem } from 'polished'
import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import NavBarButtonBase from '../NavBarButton/NavBarButtonBase'
import NavBarUserAvatar from '../NavBar/NavBarUserAvatar'
import FormatLuxLoyaltyPoints from 'luxLoyalty/components/Formatters/FormatLuxLoyaltyPoints'
import AccountMenuDrawer from '../AccountMenu/AccountMenuDrawer'
import ModalContext from 'contexts/ModalContext'
import { slideUp, slideIn, slideDown, slideOut } from 'components/utils/animations'
import { getHasLuxLoyaltyAccount } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import useCurrentLoyaltyTier from 'luxLoyalty/hooks/useCurrentLoyaltyTier'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'

const Button = styled(NavBarButtonBase)`
  padding: ${rem(12)};
  padding-left: ${rem(8)};
  display: flex;
  gap: ${rem(8)};
  align-items: center;
  text-align: start;
  max-width: ${rem(144)};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${props => props.theme.palette.neutral.default.seven};
  }
`

const NameSection = styled(CSSBreakpoint)`
  overflow: hidden;
`

const TextWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const NormalText = styled.div`
  transform: translateY(0);

  ${Button}:hover & {
    animation: ${slideUp} 0.3s ease forwards;
  }

  ${Button}:not(:hover) & {
    animation: ${slideDown} 0.3s ease forwards;
  }
`

const HoverText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);

  ${Button}:hover & {
    animation: ${slideIn} 0.3s ease forwards;
  }

  ${Button}:not(:hover) & {
    animation: ${slideOut} 0.3s ease forwards;
  }
`

interface Props {
  className?: string;
}

function NavBarUserButton(props: Props) {
  const account = useAppSelector(state => state.auth.account)
  const currency = useContext(CurrencyContext)
  const isLuxLoyaltyEnabled = useAppSelector(state => getHasLuxLoyaltyAccount(state))
  const loyaltyAccount = useAppSelector(state => state.luxLoyalty.account.data)
  const loyaltyTier = useCurrentLoyaltyTier()
  const credits = useAppSelector(state => state.auth.account.creditsByCurrency[currency])
  const loyaltyPoints = loyaltyAccount?.points ?? 0

  const showModal = useContext(ModalContext)
  const openAccountDrawer = useCallback(() => {
    showModal(<AccountMenuDrawer />)
  }, [showModal])

  return <Button
    onClick={openAccountDrawer}
    className={props.className}
    data-testid="nav-account"
  >
    <NavBarUserAvatar />
    <NameSection min="desktop">
      <BodyText variant="medium" wrap="truncate" colour="neutral-one">
        {account.givenName || 'Account'}
      </BodyText>
      {isLuxLoyaltyEnabled && !!loyaltyTier && (
        <>
          {!!loyaltyPoints && (
            <TextWrapper>
              <NormalText>
                <BodyText variant="small" wrap="truncate" colour="neutral-two">
                  <FormatLuxLoyaltyPoints abbreviated points={loyaltyPoints} />
                </BodyText>
              </NormalText>
              <HoverText>
                <BodyText variant="small" format="uppercase" weight="bold" colour={`tier-${loyaltyTier}-dark`}>
                  {getLuxLoyaltyTierDisplayName(loyaltyTier)}
                </BodyText>
              </HoverText>
            </TextWrapper>
          )}
          {!loyaltyPoints && (
            <BodyText variant="small" format="uppercase" weight="bold" wrap="truncate" colour={`tier-${loyaltyTier}-dark`}>
              {getLuxLoyaltyTierDisplayName(loyaltyTier)}
            </BodyText>
          )}
        </>
      )}

      {!!credits?.balance && !isLuxLoyaltyEnabled && (
        <BodyText variant="small" colour="neutral-two">
          <FormatCurrency value={credits.balance} />
        </BodyText>
      )}
    </NameSection>
  </Button>
}

export default React.memo(NavBarUserButton)
